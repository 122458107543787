import { type ErrorsHandbook, HttpService, BaseService } from '@/6_shared/api'
import { getMilliseconds } from '@/6_shared/lib'
import type { BaseSearchFilters } from '@/6_shared/model'
import type {
  HomeCompilationResponse,
  HomeMainResponse,
  HomeSelectionResponse,
  HomeTopResponse
} from '../model'
import { homeErrorsHandbook } from './error/HomeErrorsHandbook.ts'

class HomeService extends BaseService {
  private readonly _endpoint: string = 'homepage'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public getMainCompilations(query: BaseSearchFilters) {
    return this._http.get<HomeMainResponse>([this._endpoint, 'main'].join('/'), {
      needCancelOnRouteChange: true,
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      },
      query,
      queryAsString: true
    })
  }

  public getTopCompilation(query: BaseSearchFilters) {
    return this._http.get<HomeTopResponse>([this._endpoint, 'top'].join('/'), {
      needCancelOnRouteChange: true,
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      },
      query,
      queryAsString: true
    })
  }

  public getSelectionCompilation(query: BaseSearchFilters) {
    return this._http.get<HomeSelectionResponse>([this._endpoint, 'selection'].join('/'), {
      needCancelOnRouteChange: true,
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      },
      query,
      queryAsString: true
    })
  }

  public getCompilations(query: BaseSearchFilters) {
    return this._http.get<HomeCompilationResponse>([this._endpoint, 'compilations'].join('/'), {
      needCancelOnRouteChange: true,
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      },
      query,
      queryAsString: true
    })
  }
}

const homeService = new HomeService(HttpService, homeErrorsHandbook)

export { homeService }
