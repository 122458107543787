<template>
  <UiContainer>
    <div class="w-full py-5 lg:relative lg:pl-6 lg:pr-9 lg:pt-0">
      <UiGradientBackground
        severity="primary"
        class="bottom-0 top-auto border-primary lg:h-[calc(100%_-_1.875rem)] lg:rounded-xl lg:border"
      />
      <div class="relative flex flex-col justify-between lg:flex-row">
        <div class="mb-4 mr-5 lg:mb-0">
          <h2
            class="mb-1 font-playfair text-3xl font-semibold lg:mb-4.5 lg:whitespace-normal lg:pt-13 lg:text-[40px]"
          >
            <span class="text-text-primary">Выбор</span><span class="whitespace-nowrap"> команды Афиши</span>
          </h2>
          <p class="text-sm lg:max-w-[294px]">
            погрузитесь в&nbsp;атмосферу городских&nbsp;событий с&nbsp;нашей&nbsp;афишей
          </p>
        </div>
        <ul class="flex flex-col gap-4 lg:flex-row lg:gap-0">
          <li
            v-for="(item, index) in value.items"
            :key="item.object.id"
            class="relative hover:!z-10 lg:-mr-3 lg:w-[calc(33%_+_12px)]"
            :style="{ zIndex: 3 - index }"
          >
            <RouterLink :to="{ name: 'EventView', params: { id: item.object.id } }">
              <EventCard
                :value="item.object as Event"
                :size="isMdAndUp ? 'big' : 'small'"
                class="selection-card"
                :mask="false"
              />
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </UiContainer>
</template>

<script setup lang="ts">
import type { Compilation } from '@/5_entities/Compilation'
import { EventCard, type Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiGradientBackground } from '@/6_shared/ui'

type PropType = {
  value: Compilation
}

defineProps<PropType>()
const {
  md: [isMdAndUp]
} = useBreakpoint()
</script>

<style scoped>
.selection-card {
  transition: 0.3s ease-in-out;
}

.selection-card:hover {
  transform: translate(8px, calc(-1 * 8px)) scale(1.05);
}

::v-deep(.image-block) {
  clip-path: polygon(0 16px, 100% 0, 100% 100%, 0 100%);
  border-radius: 0 !important;
}

::v-deep(.content-block) {
  padding-left: 12px;
  padding-right: 12px;
}

::v-deep(h6) {
  font-weight: 600 !important;
}

@media screen and (max-width: 1024px) {
  ::v-deep(.event-card) {
    flex-direction: row-reverse;
  }

  ::v-deep(.content-block) {
    padding: 8px 0;
  }

  ::v-deep(.image-block) {
    clip-path: polygon(0 8px, 100% 0, 100% 100%, 0 100%);
    margin-right: unset;
  }
}
</style>
