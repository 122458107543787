import { defineStore } from 'pinia'
import { ref } from 'vue'
import { type Compilation, CompilationAdapter } from '@/5_entities/Compilation'
import { usePageLoading } from '@/6_shared/composables'
import type { Pagination } from '@/6_shared/model'
import { homeService } from '../api'
import type {
  HomeCompilationResponse,
  HomeMainResponse,
  HomeSelectionResponse,
  HomeTopResponse
} from '../model'

export const useHome = defineStore('home', () => {
  //main
  const activeCompilationTab = ref(0)
  const setActiveCompilation = (index: number) => (activeCompilationTab.value = index)

  const { initialized: mainCompilationsInitialized, load: loadMainCompilations } = usePageLoading()
  const mainCompilations = ref<undefined | Compilation[]>(undefined)
  const setMainCompilations = (data: HomeMainResponse) => {
    data.length &&
      (mainCompilations.value = data
        .filter((item) => item.items.length)
        .map((item) => new CompilationAdapter(item)))
  }
  const getMainCompilations = async (pageSize: Pagination['pageSize']) => {
    await loadMainCompilations(
      async () => {
        const compilations = await homeService.getMainCompilations({
          filter: {},
          pagination: { pageSize, currentPage: 1 }
        })
        setMainCompilations(compilations)
      },
      (error: any) => {
        throw error
      }
    )
  }

  //top
  const { load: loadTopCompilations } = usePageLoading()
  const topCompilation = ref<undefined | Compilation>(undefined)
  const setTopCompilation = (data: HomeTopResponse) => {
    data.length &&
      data[0].items.length >= 3 &&
      (topCompilation.value = new CompilationAdapter(data[0]))
  }
  const getTopCompilation = (pageSize: Pagination['pageSize']) => {
    loadTopCompilations(
      async () => {
        const compilation = await homeService.getTopCompilation({
          filter: {},
          pagination: { pageSize, currentPage: 1 }
        })
        setTopCompilation(compilation)
      },
      (e) => {
        console.log('failed to load top compilation ', e)
      }
    )
  }

  //compilations
  const { initialized: compilationInitialized, load: loadCompilations } = usePageLoading()

  const compilations = ref<undefined | Compilation[]>(undefined)
  const setCompilations = (data: HomeCompilationResponse) => {
    data.length &&
      (compilations.value = data
        .filter((item) => item.items.length > 0)
        .map((item) => new CompilationAdapter(item)))
  }
  const getCompilations = (pageSize: Pagination['pageSize']) => {
    loadCompilations(
      async () => {
        const compilations = await homeService.getCompilations({
          filter: {},
          pagination: { pageSize, currentPage: 1 }
        })
        setCompilations(compilations)
      },
      (e) => {
        console.log('failed to load compilations for home page ', e)
      }
    )
  }

  //selection
  const { load: loadSelectionCompilation } = usePageLoading()
  const selectionCompilation = ref<undefined | Compilation>(undefined)
  const setSelectionCompilation = (data: HomeSelectionResponse) => {
    data.length &&
      data[0].items.length >= 3 &&
      (selectionCompilation.value = new CompilationAdapter(data[0]))
  }
  const getSelectionCompilation = (pageSize: Pagination['pageSize']) => {
    loadSelectionCompilation(async () => {
      const compilation = await homeService.getSelectionCompilation({
        filter: {},
        pagination: { pageSize, currentPage: 1 }
      })
      setSelectionCompilation(compilation)
    })
  }

  return {
    activeCompilationTab,
    setActiveCompilation,
    mainCompilations,
    mainCompilationsInitialized,
    getMainCompilations,

    compilations,
    compilationInitialized,
    getCompilations,

    topCompilation,
    getTopCompilation,

    selectionCompilation,
    getSelectionCompilation
  }
})
