<template>
  <main class="pb-[71px] md:pb-5">
    <HomeTitle class="mb-3 lg:mb-6" />
    <HomeMainCompilations
      v-if="!mainCompilationsInitialized || mainCompilations?.length"
      :value="mainCompilations"
      :loading="!mainCompilationsInitialized"
      class="mb-[60px]"
    />
    <HomeTopCompilation v-if="topCompilation" :value="topCompilation" class="mb-9 lg:mb-18" />
    <CompilationWidget
      v-if="!compilationInitialized || (compilations && compilations.length > 1)"
      type="list"
      :value="compilations && compilations?.length > 1 ? compilations[0] : undefined"
      size="big"
      :loading="!compilationInitialized"
    />
    <HomeCategoryMarquee class="my-10 lg:my-14" />
    <CompilationWidget
      v-if="!compilationInitialized || compilations?.length"
      type="list"
      :value="compilations && (compilations.length > 1 ? compilations[1] : compilations[0])"
      size="big"
      :loading="!compilationInitialized"
    />
    <HomeSelectionCompilation
      v-if="selectionCompilation"
      :value="selectionCompilation"
      class="mt-9 lg:mt-14"
    />
    <UiContainer v-if="isMdAndUp" class="!sticky bottom-10 z-30 flex justify-end">
      <UiScrollUpButton />
    </UiContainer>
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, onMounted } from 'vue'
import { CompilationWidget } from '@/3_widgets/Compilation'
import { HomeSelectionCompilation } from '@/3_widgets/Home'
import { HomeCategoryMarquee, HomeTitle, useHome } from '@/5_entities/Home'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiScrollUpButton } from '@/6_shared/ui'

const HomeMainCompilations = defineAsyncComponent(async () => {
  const { HomeMainCompilations } = await import('@/3_widgets/Home')

  return HomeMainCompilations
})
const HomeTopCompilation = defineAsyncComponent(async () => {
  const { HomeTopCompilation } = await import('@/3_widgets/Home')

  return HomeTopCompilation
})

const {
  md: [isMdAndUp]
} = useBreakpoint()

const { getMainCompilations, getCompilations, getTopCompilation, getSelectionCompilation } =
  useHome()
const {
  mainCompilations,
  mainCompilationsInitialized,
  compilations,
  compilationInitialized,
  topCompilation,
  selectionCompilation
} = storeToRefs(useHome())

onMounted(async () => {
  await getMainCompilations(isMdAndUp.value ? 9 : 10)
  Promise.allSettled([getCompilations(8), getTopCompilation(3), getSelectionCompilation(3)])
})
</script>
