<template>
  <Vue3Marquee pause-on-hover :duration="50" class="overflow-hidden">
    <ul v-for="list in 2" :key="list" class="mx-5 flex gap-10">
      <li v-for="category in categoriesNav" :key="category.name + list + category.id">
        <RouterLink
          :to="{ name: 'CategoryItemView', params: { id: category.id } }"
          class="gradient-text text-[52px] font-bold uppercase lg:text-6xl"
        >
          {{ category.name }}
        </RouterLink>
      </li>
    </ul>
  </Vue3Marquee>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { Vue3Marquee } from 'vue3-marquee'
import { useConfiguration } from '@/5_entities/Configuration'

const { categoriesNav } = storeToRefs(useConfiguration())
</script>

<style scoped>
.gradient-text {
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--colors-gradient-tertiary-first) 0%,
    var(--colors-gradient-tertiary-second) 98%
  );
}
</style>
